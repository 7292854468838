import { ArrowDropDown, ArrowDropUp, CircleOutlined } from '@mui/icons-material'
import {
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { green, grey, red, yellow } from '@mui/material/colors'
import { Box } from '@mui/system'
import { similarity, word } from 'api'

const cols = {
  soccer: ['win', 'draw', 'loss', 'goals', 'points'],
  tennis: ['win', 'loss'],
  basketball: ['win', 'loss', 'goals'],
  volleyball: ['win', 'loss', 'goals', 'points'],
  handball: ['win', 'draw', 'loss', 'goals', 'points'],
  icehockey: ['win', 'loss', 'goals', 'points'],
  tabletennis: ['win', 'draw', 'loss', 'goals', 'points'],
  americanfootball: ['win', 'loss', 'goals'],
  cricket: ['win', 'draw', 'loss', 'points'],
  baseball: ['win', 'loss', 'pct'],
  rugbyleague: ['win', 'draw', 'loss', 'goals', 'points'],
  rugbyunion: ['win', 'draw', 'loss', 'goals', 'points'],
  snooker: ['win', 'loss', 'goals', 'points'],
  darts: ['win', 'loss', 'points'],
  badminton: ['win', 'loss', 'goals'],
  futsal: ['win', 'draw', 'loss', 'goals', 'points'],
  esports: ['win', 'draw', 'loss', 'goals', 'points'],
}

const TableCell = ({ sx, ...props }) => (
  <MuiTableCell
    sx={{
      px: { sm: 2, xs: 0.8 },
      py: { sm: 1, xs: 0.4 },
      ...sx,
    }}
    {...props}
  />
)

export default function Tables({ sport = 'soccer', rows, h, a, tolerance }) {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <>
      <Box pb={2}>
        <Table
          padding='none'
          size='small'
          sx={{
            border: theme => `1px solid ${theme.palette.divider}`,
            borderWidth: { xs: '1px 0', sm: '1px' },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align='right' sortDirection='desc' sx={{ width: 40 }}>
                {word('pos')}
              </TableCell>
              <TableCell sx={{ width: 300 }}>{word('team')}</TableCell>
              <TableCell sx={{ width: 40 }} align='center'>
                {word('games')}
              </TableCell>
              {cols[sport ?? 'soccer'].map(col =>
                col === 'goals' ? (
                  <TableCell key={col} align='center' sx={{ width: { xs: 40, sm: 90 } }}>
                    {word(xs ? '+/-' : 'goals')}
                  </TableCell>
                ) : (
                  <TableCell key={col} align='center' sx={{ width: 40 }}>
                    {word(col)}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={i}
                sx={{
                  backgroundColor:
                    similarity(String(row.team.name), String(h)) > tolerance ||
                    similarity(String(row.team.name), String(a)) > tolerance
                      ? grey[200]
                      : 'unset',

                  td: {
                    fontWeight:
                      similarity(String(row.team.name), String(h)) > tolerance ||
                      similarity(String(row.team.name), String(a)) > tolerance
                        ? 700
                        : 'unset',
                  },
                }}
              >
                <TableCell
                  align='right'
                  sortDirection='desc'
                  sx={{ borderLeft: getBorder(row?.promotion?.shortname) }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {Number(row.change) > 0 ? (
                      <ArrowDropUp fontSize='small' htmlColor={green[500]} />
                    ) : Number(row.change) < 0 ? (
                      <ArrowDropDown fontSize='small' htmlColor={red[600]} />
                    ) : (
                      <CircleOutlined
                        fontSize='small'
                        htmlColor={grey[600]}
                        sx={{ width: '1.25rem', fontSize: '0.7rem' }}
                      />
                    )}
                    {row.pos}
                  </Box>
                </TableCell>
                <TableCell>{row.team.name}</TableCell>
                <TableCell align='center'>
                  {Number(row.win) + Number(row?.draw ?? 0) + Number(row.loss)}
                </TableCell>
                {cols[sport ?? 'soccer'].map(col =>
                  col === 'goals' ? (
                    <TableCell key={col} align='center'>
                      {xs ? row.goalsfor - row.goalsagainst : row.goalsfor + ':' + row.goalsagainst}
                    </TableCell>
                  ) : (
                    <TableCell key={col} align='center'>
                      {row[col]}
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      <Box pb={2}>
        <Table
          size='small'
          sx={{
            border: theme => `1px solid ${theme.palette.divider}`,
          }}
        >
          <TableBody>
            <TableRow>
              <TableCell sx={{ borderLeft: `4px solid ${yellow[500]}` }}>
                {word('promotion')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ borderLeft: `4px solid ${yellow[800]}` }}>
                {word('promotion_playoffs')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ borderLeft: `4px solid ${red[900]}` }}>
                {word('relegation')}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  )
}

const getBorder = sn => {
  switch (sn) {
    case 'pro':
      return `4px solid ${yellow[500]}`
    case 'pro pl':
      return `4px solid ${yellow[800]}`
    case 'rel':
      return `4px solid ${red[900]}`
    default:
      return undefined
  }
}
