const dictionary = {
  en: {
    team: 'TEAM',
    pos: 'POS',
    games: 'T',
    win: 'W',
    draw: 'D',
    loss: 'L',
    goals: 'GOALS',
    points: 'PTS',
    pct: 'W%',
    promotion: 'Promotion',
    promotion_playoffs: 'Promotion Playoffs',
    relegation: 'Relegation',
    overall: 'Overall',
    home: 'Home',
    away: 'Away',
  },
  de: {
    team: 'TEAM',
    pos: 'POS',
    games: 'SP',
    win: 'S',
    draw: 'U',
    loss: 'N',
    goals: 'TORE',
    points: 'PKTE',
    pct: 'S%',
    promotion: 'Aufstieg',
    promotion_playoffs: 'Aufstiegsspiel',
    relegation: 'Abstieg',
    overall: 'Gesamt',
    home: 'Heim',
    away: 'Auswärts',
  },
  tr: {
    team: 'TAKIM',
    pos: 'POZ',
    games: 'O',
    win: 'G',
    draw: 'B',
    loss: 'M',
    goals: 'GOLLAR',
    points: 'Pu',
    pct: 'G%',
    promotion: 'Yükselme',
    promotion_playoffs: 'Yükselme Playofflar',
    relegation: 'Düşme',
    overall: 'Toplan',
    home: 'Evinde',
    away: 'Deplasman',
  },
}

export default dictionary
