import words from 'dictionary'
import { includes } from 'lodash'
import { atom } from 'recoil'
import stringSimilarity from 'string-similarity'

export const request = (path, opts) => {
  if (opts?.method?.toUpperCase() === 'POST') {
    opts.body = encodeURI(opts.body)
  }
  return fetch(`/api/${path}`, opts)
}

export const errorState = atom({
  key: 'Error',
  default: { show: false, error: '' },
})

const locale = (() => {
  let hl = (new URLSearchParams(window.location.search).get('hl') ?? navigator.language ?? 'en')
    .substr(0, 2)
    .toLowerCase()

  if (!includes(['en', 'de', 'tr'], hl)) hl = 'en'

  return hl
})()

export const word = code => words?.[locale]?.[code] ?? code

export const similarity = (a, b) => stringSimilarity.compareTwoStrings(a, b)
