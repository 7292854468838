import { Close } from '@mui/icons-material'
import { createTheme, IconButton, Tab, Tabs, ThemeProvider, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Box } from '@mui/system'
import { request, similarity, word } from 'api'
import { Tables } from 'components'
import { isEmpty } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { Helmet } from 'react-helmet-async'

const theme = createTheme({
  palette: { primary: { main: '#af0000' }, secondary: { main: '#7c7c7c' } },
})

export default function App() {
  const [data, setData] = useState(null)
  const [tab, setTab] = useState('overall')
  const location = useLocation()
  const query = useMemo(() => new URLSearchParams(location.search), [location])

  useEffect(() => {
    ;(async () => {
      setData(
        (await request(`table?id=${query.get('id')}&sport=${query.get('sport') ?? 'soccer'}`)
          // (await fetch(`sample.json`)
          .then(res => res.json())
          .then(res => res.results)
          .catch(err => console.warn(err))) ?? {
          season: { name: 'N/A' },
          overall: { tables: [{ name: 'N/A', rows: [] }] },
        }
      )
    })()
  }, [query])

  if (isEmpty(data)) return null
  if (isEmpty(data?.[tab]?.tables)) {
    return (
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>{data.season.name}</title>
        </Helmet>
        <Box sx={{ px: 2, maxWidth: 600 }}>
          <Typography variant='h5' pt={2} pb={1}>
            {data.season.name}
          </Typography>
        </Box>
      </ThemeProvider>
    )
  }

  const h = query.get('h')
  const a = query.get('a')
  const sport = query.get('sport')
  const { table, tolerance } = finder(data[tab].tables, h, a)

  const handleTabChange = (_event, newValue) => setTab(newValue)

  const seasonName = data.season.name
  const groupName = table?.name ?? ''
  const title =
    !groupName || similarity(seasonName, groupName) > 0.5
      ? seasonName
      : `${seasonName}: ${groupName}`

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box sx={{ px: { xs: 0, sm: 2 }, maxWidth: 600 }}>
        <Box
          sx={{
            bgcolor: { xs: grey[100], sm: 'transparent' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='h5' sx={{ py: 2, px: { xs: 2, sm: 0 } }}>
            {title}
          </Typography>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <IconButton onClick={() => window.close()}>
              <Close />
            </IconButton>
          </Box>
        </Box>
        {table ? (
          <>
            <Tabs variant='fullWidth' value={tab} onChange={handleTabChange}>
              <Tab value='overall' label={word('overall')} />
              <Tab value='home' label={word('home')} />
              <Tab value='away' label={word('away')} />
            </Tabs>
            <Tables rows={table.rows ?? []} {...{ sport, h, a, tolerance }} />
          </>
        ) : (
          data[tab].tables.map((table, i) => (
            <Tables key={i} rows={table.rows ?? []} {...{ sport }} />
          ))
        )}
      </Box>
    </ThemeProvider>
  )
}

const finder = (tables, h, a) => {
  let tolerance = 1
  let found = false

  while (tolerance > 0.1) {
    found = tables.find(
      // eslint-disable-next-line no-loop-func
      table =>
        table.rows.filter(
          r =>
            similarity(String(r.team.name), String(h)) > tolerance ||
            similarity(String(r.team.name), String(a)) > tolerance
        ).length === 2
    )
    if (found) {
      console.log('Found match at', tolerance, 'similarity')
      break
    }
    tolerance -= 0.05
  }

  return { table: found, tolerance }
}
